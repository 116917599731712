
.small-logo-chat{
    z-index: 100;
    /* position: fixed;
    bottom: 20px;
    align-items: center;
    justify-content: center; */
    /* background-color: white; */
}

.small-logo-chat img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}


.mesenger-auto{
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px 10px 0px 10px;
    max-width: 240px;
    min-width: 240px;
    background-color: #fff;
    bottom: 35px;
    right: 60px;
    z-index: 99;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);

}


.mesenger-auto-text{
    padding: 9px 28px 9px 14px;
    font-family: Roboto;
    font-weight: 400;
    size:18px;
    line-height: 21.09px;
    color: #2F80ED;
}



@media screen and (max-width: 500px) {
    .wrap-logo{
        position: fixed !important;
        bottom: 10px !important;
        right: 25px !important;
    }
    
  }